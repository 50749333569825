import React, { useState } from 'react'
import s from './comments.module.scss'

const Comments = (props) => {
  const [issent, setIssent] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    let currentForm = new FormData(e.target);

    fetch(`https://freekevstaticman.herokuapp.com/v2/entry/freekev/holyculture/master/comments`, {
      method: 'POST',
      body: new URLSearchParams(currentForm).toString(),
      headers: {
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      if (res.status === 200) { setIssent(true) }
    }).catch((err) => console.log(err, "There was an error, please try again"));
  }

  return (
    <div style={{ boxShadow: '0 0 10px black', padding: '1em', fontFamily: 'Cabin' }}>
      <h3 style={{ textAlign: 'center' }}>Join the Discussion...</h3>
        { !issent ? (
          <>
            <form
              className={s.form}
              onSubmit={handleSubmit}
            >
              <input
                name="fields[path]"
                type="hidden"
                value={props.path}
              />
              <input name="fields[name]" type="text" placeholder="Name" required />
              <input
                name="fields[email]"
                type="email"
                placeholder="Email"
                required
              />
              <textarea name="fields[message]" placeholder="Comment" required />
              <button type="submit" style={{ cursor: 'pointer' }}>Submit Comment</button>
            </form>
          </>
        ) : (
          <>
            <p style={{ textAlign: 'center', fontSize: '80%', fontStyle: 'italic' }}>Thank you for submitting a comment! It's pending approval.</p>
          </>
        )}
      {props.comments && props.comments.length ? (
        props.comments.map(comment => (
          <div key={comment.node._id}>
            <div>
              <span>{comment.node.name}  </span><span style={{ fontSize: '0.6em' }}>{new Date(comment.node.date).toDateString()}</span>
              <br />
              <blockquote className="comment" style={{ fontSize: 'smaller'}}>{comment.node.message}</blockquote>
            </div>
          </div>
        ))
      ) : (
          <p>No comments yet.</p>
        )}
    </div>
  )
}

export default Comments