import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Comments from '../components/comments'

export default function Template({ data }) {
  const post = data.markdownRemark

  return (
    <>
    <Helmet>
      <meta property="og:url" content={'http://www.holyculture.org' + post.frontmatter.path} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={post.frontmatter.title} />
    </Helmet>
    <Layout>
      <h1 className="blog-title">{post.frontmatter.title}</h1>
      <Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />
      <br />
      <h4>
        Posted by {post.frontmatter.author} on {post.frontmatter.date}
      </h4>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <hr />
      {post.frontmatter.tags.map(tag => (
        <small key={tag}><Link to={`/tags/${tag}`}>#{tag}  </Link></small>
      ))}
      <br /><br />
      <hr />
      <Comments path={post.frontmatter.path} comments={data.allCommentsYaml.edges} />
    </Layout>
    </>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        author
        date
        tags
        featuredImage {
            childImageSharp{
                sizes(maxWidth: 650) {
                    ...GatsbyImageSharpSizes
                }
            }
        }
      }
    }
    allCommentsYaml(filter: {path: { eq: $path }}) {
      edges {
        node {
          _id
          name
          email
          message
          date
        } 
      }
    }
  }
`
